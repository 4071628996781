<div class="modal-header">
	<h3>Upload profile image</h3>
	<button type="button" class="close nui-button secondary round" aria-label="Close" (click)="modalRef.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">

	<form>
		<div class="custom-file mb-3">
			<input type="file" class="custom-file-input" id="customFile" name="filename" (change)="fileChangeEvent($event)">
			<label class="custom-file-label" for="customFile"><span *ngIf="!fileName">Choose file</span><span *ngIf="fileName">{{fileName}}</span></label>
		</div>
	</form>

	<image-cropper
		[imageChangedEvent]="imageChangedEvent"
		[maintainAspectRatio]="true"
		[aspectRatio]="1"
		[resizeToWidth]="300"
		format="png"
		(imageCropped)="imageCropped($event)"
		(imageLoaded)="imageLoaded()"
		(cropperReady)="cropperReady()"
		(loadImageFailed)="loadImageFailed()"
	></image-cropper>

	<div class="cropper">
		<label *ngIf="croppedImage">Preview:</label>
		<img [src]="croppedImage" />
	</div>

</div>

<div class="modal-footer justify-content-between">

	<!--<svg *ngIf="isLoading" class="loader" width="52" height="52" viewBox="0 0 52 52">-->
		<!--<circle cx="26" cy="26" r="24" fill="none" stroke="#FF2C63" stroke-width="4"></circle>-->
	<!--</svg>-->

	<!--image upload-->
	<button class="btn btn-primary"
			[disabled]="croppedImage.length <= 0"
			(click)="upload()">
		Upload
	</button>

</div>
